<template>
  <el-form :model="collectionForm" :rules="rules" ref="form" label-width="100px" class="collectionForm" size="small">
    <el-form-item label="回款时间" prop="collection_date">
      <el-date-picker
        type="date"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        v-model="collectionForm.collection_date"
        style="width: 100%;"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="回款金额" prop="collection_amount">
      <el-input
        type="number"
        step="0.01"
        v-model="collectionForm.collection_amount"
        onblur="value = isNaN(parseFloat(value))? 0 : parseFloat(value)"
      ></el-input>
    </el-form-item>

    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" v-model="collectionForm.remark"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    collectionForm: Object
  },
  data() {
    return {
      isValidationOk: false,
      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        collection_date: [{ required: true, message: '请选择回款时间', trigger: 'blur' }]
        // collection_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^['-',1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }]
        // collection_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^['-',1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.isValidationOk = true
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
